import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';

import { ExhibitorItemEventThemes } from './itemtemplate/exhibitoritemeventthemes';
import { ExhibitorActions } from './itemtemplate/entityactions.exhibitor';
import { ExhibitorItemActions } from './itemtemplate/exhibitoritemaction';
import { ExhibitorNavigationActions } from './itemtemplate/entitynavigationactions.exhibitor';

export function registerExhibitorTemplateParts() {
    registerItemTemplatePart("exhibitoritemeventthemes", ExhibitorItemEventThemes);
    registerItemTemplatePart("exhibitoritemaction", ExhibitorItemActions);
    registerItemTemplatePart("entityactions.exhibitor", ExhibitorActions);
    registerItemTemplatePart("entitynavigationactions.exhibitor", ExhibitorNavigationActions);
}
