import * as React from 'react';
import { connect } from 'react-redux';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import type { Entities } from '@inwink/entities/entities';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import { AppTextLabel } from '@@services/i18nservice';
import type { States } from '@@services/services';
import { IInwinkStore, wrapReduxStore } from '@@store/index';
import { eventUserActionsModule } from '@@routes/appmodules';

export interface IExhibitorItemActionsProps extends ItemTemplateProps {
    store?: IInwinkStore;
    i18n?: States.i18nState;
    currentUser?: States.IAppUserState;
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
class ExhibitorItemActionsComponent extends React.Component<IExhibitorItemActionsProps, any> {
    constructor(props: IExhibitorItemActionsProps) {
        super(props);

        this.state = {
        };
    }

    toggleFavorite = (arg) => {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        return eventUserActionsModule().then((mod) => {
            return mod.exhibitorActions.toggleFavorite(
                this.props.data.id, this.props.i18nHelper, elt
            )(this.props.store.dispatch, this.props.store.getState) as Promise<any>;
        });
    };

    favbutton(data: States.IPersonDataStore, buttonClassName: string, showLabel: boolean) {
        let addToFavoriteActionDisplay = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.addToFavoriteActionDisplay;

        let displayAsIcon;
        let displayAsText;
        let noDisplay;
        const classes = ["favaction"];
        if (buttonClassName) {
            classes.push(buttonClassName);
        }
        if (addToFavoriteActionDisplay && addToFavoriteActionDisplay.length) {
            if (typeof addToFavoriteActionDisplay === 'string') {
                addToFavoriteActionDisplay = [addToFavoriteActionDisplay];
            }
            addToFavoriteActionDisplay.forEach((a) => {
                if (a === "icon") {
                    displayAsIcon = true;
                } else if (a === "text") {
                    displayAsText = true;
                }
                classes.push(a);
            });
        } else {
            noDisplay = true;
        }
        const addToFavoriteActionSecondary = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.addToFavoriteActionSecondary;

        if (addToFavoriteActionSecondary) {
            classes.push("lightbtn");
        }
        const eventEnded = new Date(this.props.datacontext.event.endDate) < new Date();
        if (eventEnded || (global as any).disableAuthenticatedFeatures) {
            return undefined;
        }

        const i18n = this.props.i18nHelper;

        const { onRegistrationExhibitorClicked, isSelected } = this.props.datacontext.customcontext || {};

        const hasFav = (data && data.favoriteExhibitors
            && data.favoriteExhibitors.data.filter((e) => e.status === "Active"
                && e.exhibitorId === this.props.data.id)[0]) || isSelected;

        const click = onRegistrationExhibitorClicked || this.toggleFavorite;

        if (hasFav) {
            classes.push("checked");
            return (
                <AsyncButton
                    className={classes.join(" ")}
                    onClick={click}
                    title={i18n.translate("exhibitorfavorite.remove")}
                >
                    <i className={noDisplay || displayAsIcon ? "inwink-bookmark" : ""} />
                    {' '}
                    {showLabel || displayAsText ? <AppTextLabel i18n="exhibitorfavorite.remove" /> : null}
                </AsyncButton>
            );
        }
        return (
            <AsyncButton
                className={classes.join(" ")}
                onClick={click}
                title={i18n.translate("exhibitorfavorite.add")}
            >
                <i className={noDisplay || displayAsIcon ? "inwink-bookmark" : ""} />
                {' '}
                {showLabel || displayAsText ? <AppTextLabel i18n="exhibitorfavorite.add" /> : null}
            </AsyncButton>
        );
    }

    meetingRequest = (arg: React.MouseEvent<any>, businessMeeting: boolean) => {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        return eventUserActionsModule().then((mod) => {
            return mod.exhibitorActions.requestMeeting(
                this.props.i18nHelper,
                this.props.data,
                businessMeeting,
                elt
            )(this.props.store.dispatch, this.props.store.getState) as Promise<any>;
        });
    };

    contactBtnBase(
        icon:string,
        buttonClassName: string,
        showLabel: boolean,
        trad: string,
        secondary: boolean,
        options,
        onClickCallback: (arg:React.MouseEvent<any>) => void
    ) {
        let displayAsIcon;
        let displayAsText;
        let noDisplay;
        const classes = [buttonClassName];

        if (options && options.length) {
            if (typeof options === 'string') {
                // eslint-disable-next-line no-param-reassign
                options = [options];
            }
            options.forEach((a) => {
                if (a === "icon") {
                    displayAsIcon = true;
                } else if (a === "text") {
                    displayAsText = true;
                }
                classes.push(a);
            });
        } else {
            noDisplay = true;
        }
        if (secondary) {
            classes.push("lightbtn");
        }
        const i18n = this.props.i18nHelper;
        return (
            <AsyncButton className={classes.join(" ")} onClick={onClickCallback} title={i18n.translate(trad)}>
                <i className={noDisplay || displayAsIcon ? icon : ""} />
                {' '}
                {showLabel || displayAsText ? <AppTextLabel i18n={trad} /> : null}
            </AsyncButton>
        );
    }

    getRequestMeetingBtn(buttonClassName: string, showLabel: boolean, businessMeeting: boolean) {
        const contactActionDisplay = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.contactActionDisplay;
        const contactActionSecondary = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.contactActionSecondary;

        return this.contactBtnBase(
            "inwink-calendar",
            buttonClassName,
            showLabel,
            "exhibitor.askmeeting",
            contactActionSecondary,
            contactActionDisplay,
            (arg) => this.meetingRequest(arg, businessMeeting)
        );
    }

    sendMessage = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        return eventUserActionsModule().then((mod) => {
            return mod.exhibitorActions.contact(
                this.props.data, this.props.i18nHelper, elt
            )(this.props.store.dispatch, this.props.store.getState) as Promise<any>;
        });
    };

    getSendMessageBtn(buttonClassName: string, showLabel: boolean) {
        const sendMessageActionDisplay = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.sendMessageActionDisplay;
        const sendMessageActionSecondary = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.sendMessageActionSecondary;

        return this.contactBtnBase(
            "inwink-chat",
            buttonClassName,
            showLabel,
            "exhibitor.contact",
            sendMessageActionSecondary,
            sendMessageActionDisplay,
            this.sendMessage
        );
    }

    render() {
        const userData = this.props.currentUser && this.props.currentUser.currentUser && this.props.currentUser.currentUser.data;
        const exhibitor = this.props.data as Entities.IExhibitor;
        let favbtn;
        let requestMeetingBtn;
        const eventconf = this.props.datacontext.event
            && this.props.datacontext.event
            && this.props.datacontext.event.configuration;
        const disableLogin = eventconf?.companion?.disableUserCanLogin;
        const allowFav = eventconf && eventconf.companion
            && (eventconf.companion.useExhibitorFavorites || !disableLogin);
        const allowNetworkingContact = false;
        const allowBusinessMeetingContact = eventconf?.exhibitors?.businessMeetingConfig?.enabled
            && eventconf.exhibitors?.businessMeetingConfig?.allowAttendeeToContactExhibitor;
        const buttonClassName = (this.props.template.properties && this.props.template.properties.buttonClassName) || "";
        const showLabels = this.props.template.properties && this.props.template.properties.showLabels;
        const sendMessageActionDisplay = this.props.datacontext
            && (this.props.datacontext as any).templateOptions
            && (this.props.datacontext as any).templateOptions.sendMessageActionDisplay;
        const showSendMessages = (sendMessageActionDisplay
            || (
                this.props.template.properties
                && this.props.template.properties.showSendMessages
            )
        );
        const hasCockpit = eventconf && eventconf.exhibitors && eventconf.exhibitors.hasExhibitors;
        let sendMessageBtn = null;

        if (exhibitor && !disableLogin) {
            if (allowFav) {
                favbtn = this.favbutton(userData, buttonClassName, showLabels);
            }

            if (allowBusinessMeetingContact) {
                requestMeetingBtn = this.getRequestMeetingBtn("requestmeeting " + buttonClassName, showLabels, true);
            } else if (allowNetworkingContact) {
                requestMeetingBtn = this.getRequestMeetingBtn("requestmeeting " + buttonClassName, showLabels, false);
            }

            if (hasCockpit && showSendMessages) {
                sendMessageBtn = this.getSendMessageBtn("sendmessage " + buttonClassName, showLabels);
            }
        }

        return (
            <section
                className={"entity-actions exhibitoritemactions"
                + (this.props.template.className ? " " + this.props.template.className : "")}
            >
                {requestMeetingBtn}
                {sendMessageBtn}
                {favbtn}
            </section>
        );
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        i18n: state.i18n,
        currentUser: state.user
    };
}

export const ExhibitorItemActions: new (any) => React.Component<IExhibitorItemActionsProps, any> = connect(
    mapStateToProps
)(wrapReduxStore(ExhibitorItemActionsComponent) as any) as any;
