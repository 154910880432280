import { Entities } from '@inwink/entities/entities';
import { getEntityPredicate } from '../../../../data/entityfilters';
import { SortAndFilterItems } from '../../../../components/dynamicpage/teaseritems.props';
import { States } from '../../../../services/services';

export function setCurrentExhibitorsContext(eventdata: States.IEventState, exhibitorIds: string[], currentExhibitorId: string) {
    if (typeof (sessionStorage) !== 'undefined') {
        const maxLength = 100;
        if (exhibitorIds.length > maxLength) {
            const idx = exhibitorIds.indexOf(currentExhibitorId);
            const half = maxLength / 2;
            const startIdx = idx - half > -1 ? idx - half : 0;
            const prevs = exhibitorIds.slice(startIdx, idx);
            const endIdx = idx + half > exhibitorIds.length ? exhibitorIds.length : idx + half;
            const nexts = exhibitorIds.slice(idx, endIdx);
            exhibitorIds = prevs.concat(nexts);
        }
        sessionStorage.setItem(eventdata.eventid + '_exhibitors_current_context_data', JSON.stringify(exhibitorIds));
    }
}

export function getCurrentExhibitorsContext(eventdata: States.IEventState): string[] {
    if (typeof (sessionStorage) !== 'undefined') {
        const res = sessionStorage.getItem(eventdata.eventid + '_exhibitors_current_context_data');
        if (res) {
            return JSON.parse(res);
        }
    }
    return null;
}

export function defaultExhibitorSort(a, b) {
    if (a.name != null && a.name !== undefined) { return a.name.localeCompare(b.name); }
}

export function getHomeExhibitors(eventdata: States.IEventDataStore): Entities.IExhibitor[] {
    const res = eventdata.exhibitors.data;
    const asArray = Array.prototype.slice.call(res);
    return asArray;
}

export function getThemeExhibitors(themeid: string, eventdata: States.IEventDataStore): Entities.IExhibitor[] {
    const res = eventdata.exhibitors.data.filter((s) => {
        return s.eventThemes && s.eventThemes.some((e) => e.id === themeid);
    });
    let asArray = Array.prototype.slice.call(res);
    asArray = asArray.sort((a, b) => {
        if (a.name != null && a.name !== undefined) { return a.name.localeCompare(b.name); }
        return 0;
    });
    return asArray;
}

export function getJourneyExhibitors(journeyid: string, eventdata: States.IEventDataStore): Entities.IExhibitor[] {
    const res = eventdata.exhibitors.data.filter((s) => {
        return s.journeys?.some((e) => e.id === journeyid);
    });
    let asArray = Array.prototype.slice.call(res);
    asArray = asArray.sort((a, b) => {
        if (a.name != null && a.name !== undefined) { return a.name.localeCompare(b.name); }
        return 0;
    });
    return asArray;
}

export function getRecommendationPredicate(user: States.IAppUserState,
    filter: any, isTeaser?: boolean): (exhibitor: Entities.IExhibitor) => boolean {
    let recommendationPredicate: (exhibitor: Entities.IExhibitor) => boolean = () => true;
    if (user?.checked && (filter?.my_recommendations || isTeaser)) {
        const favoriteEventThemes = user?.currentUser?.data?.favoriteEventThemes?.data;
        const favoriteEventThemeIds: string[] = favoriteEventThemes?.map((et) => et.eventThemeId)
            || [];

        recommendationPredicate = (exhibitor) => exhibitor.eventThemes?.length && exhibitor.eventThemes
            .some((e) => favoriteEventThemeIds.indexOf(e.id) > -1);
    }

    return recommendationPredicate;
}

function getFavoritePredicate(user: States.IAppUserState, filter: any): (exhibitor: Entities.IExhibitor) => boolean {
    let favoritePredicate: (exhibitor: Entities.IExhibitor) => boolean = () => true;
    if (user?.checked && filter?.bookmarked) {
        const favoriteExhibitors = user?.currentUser?.data?.favoriteExhibitors?.data;
        const favoriteExhibitorIds: string[] = favoriteExhibitors?.map((fe) => fe.exhibitorId)
            || [];

        favoritePredicate = (exhibitor) => favoriteExhibitorIds
            .some((f) => f === exhibitor.id);
    }

    return favoritePredicate;
}

export function filterExhibitors(
    user: States.IAppUserState, i18n: Entities.i18nService, eventdata: States.IEventDataStore,
    filter: any, fieldtemplate: Entities.IFieldTemplate, searchable: Entities.IFilterTemplate,
    properties: any
): Entities.IExhibitor[] {
    let noValNoShow;
    if (filter?.affiliatedToId?.length && Array.isArray(filter.affiliatedToId)) {
        // eslint-disable-next-line no-param-reassign
        filter.affiliatedToId = filter.affiliatedToId[0];
    }
    if (filter.affiliatedTo) {
        noValNoShow = true;
    }
    const predicate = getEntityPredicate<Entities.IExhibitor>(filter, fieldtemplate, searchable, noValNoShow);
    const recommendationPredicate = getRecommendationPredicate(user, filter);
    const favoritePredicate = getFavoritePredicate(user, filter);
    const res = eventdata.exhibitors.data.filter((e) => predicate(e)
        && recommendationPredicate(e) && favoritePredicate(e));
    let asArray = Array.prototype.slice.call(res) as Entities.IExhibitor[];
    asArray = asArray.sort((a, b) => {
        if (a.name != null && a.name !== undefined) { return a.name.localeCompare(b.name); }
        return 0;
    });
    return SortAndFilterItems(user, i18n, asArray, properties, properties.allowShuffle, null, fieldtemplate?.workTemplate);
}

export function getExhibitorsByIds(exhibids: { id: string }[], eventdata: States.IEventDataStore): Entities.ISession[] {
    const res = [];
    exhibids.forEach((s) => {
        const exhib = eventdata.exhibitors.data.filter((exhibitor) => s.id === exhibitor.id)[0];
        if (exhib) res.push(exhib);
    });

    // let asArray = Array.prototype.slice.call(res);
    // asArray = asArray.sort((a, b) => {
    //     if (a.name != null && a.name != undefined) { return a.name.localeCompare(b.name); }
    //     return 0;
    // });
    return res;
}

export function getAffiliated(exhibitor: Entities.IExhibitor, grabParent: boolean,
    grabChildren: boolean,
    grabBrothers: boolean,
    eventdata: States.IEventDataStore): Entities.ISession[] {
    const res = [];
    // res.push(...eventdata.exhibitors.data.filter((e) => e.affiliatedToId === exhibitor.id));
    if (exhibitor.affiliatedToId && grabParent) {
        const parent = eventdata.exhibitors.data.filter((e) => e.id === exhibitor.affiliatedToId)[0];
        if (parent) res.push(parent);
    }
    if (exhibitor.id && grabChildren) {
        const children = eventdata.exhibitors.data
            .filter((e) => e.affiliatedToId === exhibitor.id);
        if (children.length) {
            res.push(...children);
        }
    }
    if (exhibitor.affiliatedToId && grabBrothers) {
        const brothers = eventdata.exhibitors.data
            .filter((e) => e.id !== exhibitor.id && e.affiliatedToId === exhibitor.affiliatedToId);
        if (brothers.length) {
            res.push(...brothers);
        }
    }

    return res;
}

export function filterRooms(user: States.IAppUserState, i18n: States.i18nState, rooms: Entities.IRoom[], filter: any,
    fieldtemplate: Entities.IFieldTemplate, searchable: Entities.IFilterTemplate, properties: any): Entities.IRoom[] {
    const predicate = getEntityPredicate<Entities.IRoom>(filter, fieldtemplate, searchable);
    const res = rooms.filter((s) => {
        return predicate(s);
    });
    return SortAndFilterItems(user, i18n, res, properties, false, null, fieldtemplate?.workTemplate);
}
