import * as React from 'react';
import { Link } from 'react-router-dom';
import { States } from '../../../../../services/services';
import { AppTextLabel } from '../../../../../services/i18nservice';
import { getCurrentExhibitorsContext } from '../data.exhibitors';

import './entitynavigationactions.exhibitor.less';

export interface IExhibitorNavigationActionsProps {
    data: any;
    datacontext: any;
    i18n?: States.i18nState;
    currentUser?: States.IAppUserState;
    urlservice?: States.IAppUrlContext;
}

export interface IExhibitorNavigationActionsState {
    nextExhibitorId?: string;
    previousExhibitorId?: string;
}

export class ExhibitorNavigationActions
    extends React.Component<IExhibitorNavigationActionsProps, IExhibitorNavigationActionsState> {
    constructor(props: IExhibitorNavigationActionsProps) {
        super(props);

        const currentExhibitorsContext = getCurrentExhibitorsContext(this.props.datacontext.eventstate);
        let previousExhibitorId;
        let nextExhibitorId;
        if (currentExhibitorsContext) {
            const idx = currentExhibitorsContext.indexOf(props.data.id);
            if (idx > -1) {
                if (idx > 0) {
                    previousExhibitorId = currentExhibitorsContext[idx - 1];
                }

                if (idx < currentExhibitorsContext.length) {
                    nextExhibitorId = currentExhibitorsContext[idx + 1];
                }
            }
        }
        this.state = {
            previousExhibitorId: previousExhibitorId,
            nextExhibitorId: nextExhibitorId
        };
    }

    render() {
        let previousbtn;
        let nextbtn;

        if (this.state.previousExhibitorId) {
            previousbtn = <Link
                role="button"
                className="lightbtn"
                to={this.props.urlservice.pageUrl(`partners/${this.state.previousExhibitorId}`)}
                replace
            >
                <i className="inwink inwink-chevron-left" /> <AppTextLabel i18n="actions.previous" />
            </Link>;
        }

        if (this.state.nextExhibitorId) {
            nextbtn = <Link
                role="button"
                className="lightbtn"
                to={this.props.urlservice.pageUrl(`partners/${this.state.nextExhibitorId}`)}
                replace
            >
                <AppTextLabel i18n="actions.next" /> <i className="inwink inwink-chevron-right" />
            </Link>;
        }

        return <section className="entity-navigation">
            {previousbtn}
            <span className="separator" />
            {nextbtn}
        </section>;
    }
}
