import * as React from 'react';
import { connect } from 'react-redux';
import { Entities } from '@inwink/entities/entities';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { States } from '../../../../../services/services';
import { AppTextLabel } from '../../../../../services/i18nservice';
import { IInwinkStore, wrapReduxStore } from '../../../../../store';
import { eventUserActionsModule } from '../../../../../routes/appmodules';

export interface IExhibitorActionsProps {
    data: any;
    datacontext: any;
    i18n?: States.i18nState;
    currentUser?: States.IAppUserState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
}

@withI18nHelper()
class ExhibitorActionsComponent extends React.Component<IExhibitorActionsProps, any> {
    constructor(props: IExhibitorActionsProps) {
        super(props);

        this.state = {
        };
    }

    toggleFavorite = (arg) => {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        return eventUserActionsModule().then((mod) => {
            return mod.exhibitorActions.toggleFavorite(
                this.props.data.id, this.props.i18nHelper, elt
            )(this.props.store.dispatch, this.props.store.getState) as Promise<any>;
        });
    };

    favbutton(data: States.IPersonDataStore) {
        const eventEnded = new Date(this.props.datacontext.event.endDate) < new Date();
        if (eventEnded || (global as any).disableAuthenticatedFeatures) {
            return undefined;
        }

        const hasFav = data?.favoriteExhibitors?.data
            .filter((e) => e.status === "Active" && e.exhibitorId === this.props.data.id)[0];
        if (hasFav) {
            return (
                <AsyncButton className="checked" onClick={this.toggleFavorite}>
                    <i className="inwink-bookmark" />
                    {' '}
                    <AppTextLabel i18n="exhibitorfavorite.remove" />
                </AsyncButton>
            );
        }
        return (
            <AsyncButton className="lightbtn" onClick={this.toggleFavorite}>
                <i className="inwink-bookmark" />
                {' '}
                <AppTextLabel i18n="exhibitorfavorite.add" />
            </AsyncButton>
        );
    }

    meetingRequest = (arg: React.MouseEvent<any>, businessMeeting: boolean) => {
        const elt = arg.target as HTMLElement;
        return eventUserActionsModule().then((mod) => {
            return mod.exhibitorActions.requestMeeting(
                this.props.i18nHelper,
                this.props.data,
                businessMeeting,
                elt
            )(this.props.store.dispatch, this.props.store.getState) as Promise<any>;
        });
    };

    meetingBtn(businessMeeting: boolean) {
        return (
            <AsyncButton onClick={(arg) => this.meetingRequest(arg, businessMeeting)}>
                <i className="inwink-calendar" />
                {' '}
                <AppTextLabel i18n="exhibitor.askmeeting" />
            </AsyncButton>
        );
    }

    contactBtn() {
        return (
            <AsyncButton onClick={(arg) => this.sendMessage(arg)}>
                <i className="inwink-chat" />
                {' '}
                <AppTextLabel i18n="exhibitor.contact" />
            </AsyncButton>
        );
    }

    sendMessage(arg: React.MouseEvent<any>) {
        arg.preventDefault();
        arg.stopPropagation();
        const elt = arg.target as HTMLElement;
        return eventUserActionsModule().then((mod) => {
            return mod.exhibitorActions.contact(
                this.props.data, this.props.i18nHelper, elt
            )(this.props.store.dispatch, this.props.store.getState) as Promise<any>;
        });
    }

    render() {
        const userData = this.props.currentUser && this.props.currentUser.currentUser && this.props.currentUser.currentUser.data;
        const exhibitor = this.props.data as Entities.IExhibitor;
        let favbtn;
        let meetingbtn;
        let contactbtn;
        const eventconf: Entities.IEventDetailConfiguration = this.props.datacontext.event?.configuration;
        const disableLogin = eventconf?.companion?.disableUserCanLogin;
        const allowFav = eventconf && eventconf.companion && (eventconf.companion.useExhibitorFavorites || !disableLogin);
        const allowNetworkingContact = false;
        const allowBusinessMeetingContact = eventconf?.exhibitors?.businessMeetingConfig?.enabled
            && eventconf?.exhibitors?.businessMeetingConfig?.allowAttendeeToContactExhibitor;
        const hasCockpit = eventconf?.exhibitors?.hasExhibitors;

        if (exhibitor && !disableLogin) {
            if (allowFav) {
                favbtn = this.favbutton(userData);
            }

            if (allowBusinessMeetingContact) {
                meetingbtn = this.meetingBtn(true);
            } else if (allowNetworkingContact) {
                meetingbtn = this.meetingBtn(false);
            }

            if (hasCockpit) {
                contactbtn = this.contactBtn();
            }
        }

        return (
            <section className="entity-actions">
                {favbtn}
                {meetingbtn}
                {contactbtn}
            </section>
        );
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        i18n: state.i18n,
        currentUser: state.user
    };
}

export const ExhibitorActions: new (any)
=> React.Component<IExhibitorActionsProps, any> = connect(
    mapStateToProps
)(wrapReduxStore(ExhibitorActionsComponent) as any) as any;
